<template>
  <v-app>
    <!-- 小标题 -->
    <v-container>
      <v-app-bar color="primary" app>
        <v-container>
          <v-row class="d-flex align-center justify-space-between">
            <v-col cols="auto">
              <h2>
                <span class="save-text">Save</span
                ><span class="save-background">Tube</span>
              </h2>
            </v-col>
            <v-col cols="auto">
              <v-select
                v-model="selectedLanguage"
                :items="languages"
                flat
                dense
                class="language-select"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>
      <v-main>
        <v-row class="d-flex justify-center mt-4">
          <h5 class="ms-font-xl" style="font-weight: 300">
            <span class="custom-background">SaveTube.top</span> Youtube Video
            Downloader
          </h5>
        </v-row>

        <v-row class="d-flex justify-center mt-2">
          <v-col cols="auto">
            <p class="ms-font-m" style="font-weight: 300; margin: 0">
              No Download Needed
            </p>
          </v-col>
          <v-col cols="auto">
            <p class="ms-font-m" style="font-weight: 300; margin: 0">
              Free of Charge
            </p>
          </v-col>
          <v-col cols="auto">
            <p class="ms-font-m" style="font-weight: 300; margin: 0">
              Fast and Direct
            </p>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <!-- 搜索框 -->

    <template>
      <div>
        <v-slide-y-transition>
          <v-card v-if="panelVisible" class="panel">
            <v-card-text>
              Please check your browser's download bar
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
      </div>
    </template>

    <v-container>
      <v-row justify-center no-gutters>
        <v-col cols="12">
          <v-text-field
            flat
            tile
            dense
            solo-inverted
            v-model="searchQuery"
            placeholder="Paste your youtube url......"
            persistent-placeholder
            input-props="blue darken-2"
            autofocus
            @click:append="search"
          >
            <template v-slot:prepend-inner>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="selectedItem"
                    flat
                    tile
                    solo-inverted
                    hide-details
                    class="centered-input"
                    :style="{
                      background: 'transparent',
                      'font-weight': 'bold',
                    }"
                  ></v-text-field>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index2) in items"
                    :key="item"
                    @click="selectItem(item, index2)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-divider vertical dense></v-divider>
            </template>
            <template v-slot:append>
              <!-- 将容器设置为右对齐 -->
              <v-btn
                color="primary"
                class="mr-n2"
                depressed
                x-large
                tile
                @click="search"
                v-ripple
                >Download
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <!-- 添加一个垂直分割线 -->
          <p class="ms-font-s caption mt-0">
            <v-icon small color="blue">mdi-download</v-icon>After you click the
            download button, the download will start immediately
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-footer color="black" app>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="6" class="text-center">
            <span class="white--text"
              >&copy; 2024 SaveTube All rights reserved.</span
            >
          </v-col>
          <v-col cols="12" sm="6" class="text-center">
            <v-icon small color="white">mdi-facebook</v-icon>
            <v-icon small color="white" class="ml-3">mdi-twitter</v-icon>
            <v-icon small color="white" class="ml-3">mdi-instagram</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  props: {
    currency: Object,
    hover: Boolean,
  },
  metaInfo: {
    title: "SaveTube - YouTube Video Downloader",
    meta: [
      {
        name: "description",
        content: "Download YouTube videos quickly and easily with SaveTube.",
      },
      { name: "keywords", content: "YouTube, Video, Downloader, SaveTube" },
    ],
  },
  data() {
    return {
      panelVisible: false,
      selectedLanguage: "English",
      languages: ["English", "Spanish", "French", "German", "Chinese"],
      menu: false,
      selectedIndex: null,
      selectedItem: "720p",
      selectSearchItems: [
        /* your currency items array */
      ],

      appBarHeight: "25vh", // 设置高度为当前窗口高度的四分之一,

      searchQuery: "",
      loading: false,
      error: "",

      items: [
        "mp3",
        "144p",
        "240p",
        "360p",
        "480p",
        "720p",
        "1080p",
        "1440p",
        "4k",
        "8k",
      ],
    };
  },
  methods: {
    openPanel() {
      if (this.searchQuery !== "") {
        this.panelVisible = true;
        // Set a timeout to hide the panel after 5 seconds
        setTimeout(() => {
          this.panelVisible = false;
        }, 5000);
      }
    },
    selectItem(item) {
      // 更新选定的项目
      this.selectedItem = item;
      this.menu = false;

      // 关闭菜单
    },

    async search() {
      // 如果搜索框中有数据，则执行导航
      if (this.searchQuery && this.searchQuery.trim() !== "") {
        // 定义请求体
        this.isDownloading = true;
        const requestBody = {
          url: this.searchQuery,
          vCodec: "h264",
          vQuality: this.selectedItem,
          aFormat: "mp3",
          filenamePattern: "classic",
          isAudioOnly: false,
          isTTFullAudio: false,
          isAudioMuted: false,
          dubLang: true,
          disableMetadata: true,
          twitterGif: false,
          tiktokH265: false,
        };

        try {
          // 发送POST请求
          const response = await axios.post(
            "https://cryptoicons.info/api/get_video",
            requestBody
          );
          this.openPanel();
          console.log("Response:", response.data);
          const downloadUrl = response.data.url;
          // 创建一个虚拟的<a>标签并触发点击事件
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", ""); // 设置下载属性，如果需要自定义文件名，可以设置第二个参数
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // 在此处处理响应数据，例如导航到另一个页面并传递数据
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },
  },

  computed: {},
};
</script>

<style scoped>
.save-text {
  color: white; /* 设置 Save 字体颜色为白色 */
  font-weight: bold; /* 设置字体加粗 */
}
.save-background {
  background-color: rgb(251, 173, 65); /* 使用 RGB 值设置背景颜色 */
  color: black; /* 设置文字颜色为黑色 */
  padding: 2px 2px;
  border-radius: 3px; /* 长方形椭圆效果 */
}
.custom-background {
  background-color: rgb(251, 173, 65); /* 使用与 primary 颜色匹配的颜色 */
  color: white;
  padding: 10px 20px;
  border-radius: 25px; /* 长方形椭圆效果 */
  display: inline-block;
}
.panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 16px;
}
.decrease {
  background-color: rgba(255, 192, 192, 0.5);
}

.increase {
  background-color: rgba(192, 255, 192, 0.5);
}
.currency-table thead {
  font-weight: 200; /* 设置表头字体粗细 */
  color: #888; /* 设置表头字体颜色为灰色 */
  border-bottom: 1px solid #ccc; /* 在表头下方添加下划线 */
  padding-top: 15px; /* 设置表头上方间距 */
  padding-bottom: 15px; /* 设置表头下方间距 */
  cursor: pointer;
}

/* 当悬停在按钮上时，改变背景色 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.selected-row {
  background-color: lightgray;
}

.centered-input >>> input {
  text-align: center;
  background: transparent;
}
.centered-input >>> .v-input__slot {
  background: transparent !important;
}
.v-text-field >>> .v-input__control > .v-input__slot {
  padding: 0 !important;
}

.v-text-field >>> v-text-field__slot {
  text-align: center !important;
}

.gray-background {
  background-color: lightgray; /* 设置点击后的背景色 */
}
.currency-card {
  cursor: pointer;
  transition: background-color 0.3s; /* 添加过渡动画 */
}

.currency-card:hover {
  background-color: lightgray; /* 设置默认悬停背景色 */
}

.currency-content {
  display: flex;
  align-items: center;
}

.currency-card:hover {
  cursor: pointer;
}

.currency-content {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

.currency-name {
  font-weight: 300; /* 300 是典型的细长字体 */
}
.currency-card {
  padding-left: calc((100% - 35px) / 2.5); /* 计算左内边距 */
}
.container-center {
  display: flex;
  justify-content: center; /* 外部容器居中对齐 */
}

.currency-content {
  display: flex;
  align-items: left;
}

.icon {
  margin-right: 10px; /* 图片和文字之间的间距 */
}

.artistic-text {
  font-family: "Arial", sans-serif; /* 设置字体 */
  font-size: 33px; /* 设置字体大小 */
  font-weight: 400; /* 设置字体粗细 */
  letter-spacing: 0px; /* 设置字间距 */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* 添加文本阴影 */
  color: rgb(0, 0, 0); /* 设置文本颜色 */
}
.custom-dropdown-background .v-menu__content {
  background-color: #ffffff; /* 设置下拉框背景色为白色 */
}

.ms-font-xl {
  font-size: 24px;
}
.ms-font-l {
  font-size: 18px;
}

.custom-dropdown {
  background-color: transparent; /* 设置下拉框背景色为透明 */
}

#app {
  height: 100%;
  background-color: #e6eaf2;
  background-position: center; /* 居中显示背景图片 */
}

body {
  height: 100%;
  margin: 0; /* 移除默认的页面边距 */
  font-family: "Roboto", sans-serif; /* 设置字体 */
}
</style>
